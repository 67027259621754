<template>
  <v-list-item>
    <v-list-item-avatar>
      <img :src="avatar">
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-html="clientNameDisplay" />
      <v-list-item-subtitle v-html="phoneFormatted" />
    </v-list-item-content>
    <v-list-item-action>
      <slot name="action" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>

  export default {
    name: 'AccountListItem',
    props: {
      account: { type: [Object], required: true },
    },
    data () {
      return {

      }
    },
    computed: {
      clientNameDisplay () {
        return this.account.client_name || this.$t('no_name')
      },
      avatar () {
        return this.account.avatar || this.account?.user.avatar
      },
      phone () {
        return this.account.phone || this.account?.user.phone
      },
      phoneFormatted () {
        return this.phone ? '+' + this.phone : null
      },

    },
    watch: {

    },
    created () {

    },

    methods: {

    },
  }
</script>
